import React from "react";
import Banner from "hw/ui/banner";
import { Heading, Text, A } from "hw/ui/text";
import { brandName } from "hw/brand";
import theme from "hw/ui/theme";
import { Stack } from "hw/ui/layout";

export function EolBanner() {
  return (
    <Banner
      type="warning"
      extend={{
        overflow: "visible",
        maxHeight: "none",
        marginBottom: theme.space.md,
      }}
    >
      <Stack space="sm">
        <Heading level="4">{brandName()} is being retired</Heading>
        <Text>
          {brandName()} will no longer be available or supported after{" "}
          <Strong>September 30, 2025</Strong>. To ensure a smooth transition,
          please access and save your data before this date to avoid any
          disruptions.{" "}
          <A
            target="_blank"
            rel="noreferrer noopener"
            href="https://faq.hellosign.com/hc/en-us/articles/11648344615821--How-to-export-Dropbox-Forms-data-to-a-CSV"
          >
            Learn how to export your data.
          </A>
        </Text>
      </Stack>
    </Banner>
  );
}

function Strong(props: { children: React.ReactNode }) {
  return (
    <Text extend={{ fontWeight: theme.fontWeight.semibold }} as="span">
      {props.children}
    </Text>
  );
}
