import * as React from "react";

export * from "./constants";

type Action =
  | { type: "update-team-name" }
  | { type: "toggle-flag"; flag: string }
  | { type: "set-next-folder-guid"; guid: string }
  | { type: "clear-next-folder-guid" }
  | { type: "unset-education" };

export type Session = {
  user: {
    name: string;
    id: string;
    email: string;
    isSignupComplete: boolean;
    isPaid: boolean;
    team: {
      guid: string;
    };
    role: string;
    flags: Array<string>;
    dbx: {
      appKey: string;
      redirectUri: string;
    };
    isInternal: boolean;
    showEducation?: boolean;
    hsHwCrossPromo?: boolean;
  };
  token: string;
  nextFolderGuid?: string | null | undefined;
  hsAppFqdn: string;
};

const StateContext = React.createContext<Session | null>(null);
const DispatchContext = React.createContext<React.Dispatch<Action>>(() => {});

export const ActionTypes = {
  UpdateTeamName: "update-team-name",
};

/**
 * Provider for the session store. This data will generally come in as
 * initial state from the server.
 */
export function Provider(props: { children: React.ReactNode; value: Session }) {
  const { value, children } = props;
  const [state, dispatch] = React.useReducer(reducer, value || {});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

/**
 * Hook for accessing the session state:
 *
 * @example
 * function MyComponent(props) {
 *   const { user }  = useSession();
 *
 *   //...
 * }
 */
export function useSession() {
  const sessionState = React.useContext(StateContext);

  if (!sessionState) {
    throw new Error(noProviderMsg());
  }

  return sessionState;
}

/**
 * Hook for accessing the dispatcher for the session if a state update is needed
 *
 * @example
 * function MyComponent(props) {
 *   const dispatch = useDispatch();
 *
 *   React.useEffect(() => {
 *     dispatch({ type: 'some-action' })
 *   });
 *   //...
 * }
 */
export function useDispatch() {
  const dispatcher = React.useContext(DispatchContext);

  if (!dispatcher) {
    throw new Error(noProviderMsg());
  }

  return dispatcher;
}

type ActiveFeatureFlag =
  | "editor_redesign"
  | "can_toggle_editor_redesign"
  | "can_use_code_view"
  | "debugger"
  | "v2_drafts_by_default"
  | "v2_auto_build"
  | "test_modal"
  | "manage_cookies";

/**
 * Hooks for accessing feature flags.
 *
 * @example
 * function MyComponent(props) {
 *   const enabled = useFlag('template_library');
 *
 *   if (enabled) {
 *     return 'yup';
 *   } else {
 *     return 'nope';
 *   }
 * }
 */
export function useFlag(flag: ActiveFeatureFlag) {
  const { user } = useSession();
  return (user.flags || []).includes(flag);
}

/**
 * Access the session state via higher-order component:
 *
 * @example
 * function MyComponent(props) {
 *   const { session } = props;
 *   //...
 * }
 *
 * export default withSession(MyComponent)
 */
export function withSession<Props>(
  Component: React.ComponentType<Props>
): React.ComponentType<Props & { session: Session }> {
  return function WithSession(props) {
    const session = useSession();

    return <Component {...props} session={session} />;
  };
}

/**
 * Accesses the dbx integration config variables
 * @example
 * function integrate() {
 *   const {appKey, redirectUri} = dbxIntegrationVars();
 * }
 */
export function useDBXIntegrationVars() {
  const { user } = useSession();
  return user.dbx;
}

function reducer(state: Session, action: Action) {
  if (action.type === ActionTypes.UpdateTeamName) {
    return {
      ...state,
      user: {
        ...state.user,
        isSignupComplete: true,
      },
    };
  }

  if (action.type === "toggle-flag") {
    const { flag } = action;
    const currentFlags = state.user.flags ?? [];
    return {
      ...state,
      user: {
        ...state.user,
        flags: currentFlags.includes(flag)
          ? currentFlags.filter((f) => f !== flag)
          : [...currentFlags, flag],
      },
    };
  }

  if (action.type === "unset-education") {
    return {
      ...state,
      user: {
        ...state.user,
        showEducation: false,
      },
    };
  }

  if (action.type === "set-next-folder-guid") {
    return {
      ...state,
      nextFolderGuid: action.guid,
    };
  }

  if (action.type === "clear-next-folder-guid") {
    return {
      ...state,
      nextFolderGuid: null,
    };
  }

  return state;
}

function noProviderMsg() {
  return `
  Trying to access the session data without the context. Make sure
  you've wrapped your component tree within the session Provider`;
}
